import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "container" }
const _hoisted_4 = { class: "container" }
const _hoisted_5 = { class: "container" }
const _hoisted_6 = { class: "container" }
const _hoisted_7 = { class: "btn-link" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SectionTitle = _resolveComponent("SectionTitle")!
  const _component_SectionHeader = _resolveComponent("SectionHeader")!
  const _component_CustomCards = _resolveComponent("CustomCards")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_b_button = _resolveComponent("b-button")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_SectionTitle, {
      title: _ctx.t('servicesPage.projects.header.title')
    }, null, 8, ["title"]),
    _createVNode(_component_SectionHeader, {
      title: _ctx.t('servicesPage.projects.categories.cat1.header.title'),
      description: _ctx.t('home.projects.cards.description1'),
      image: _ctx.imgEducational,
      txtPadding: "17rem",
      scale: "0.5",
      height: "350px"
    }, null, 8, ["title", "description", "image"]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_CustomCards, {
        list: _ctx.CustomCardsListCat1,
        imgWidth: "100%",
        displayTitle: "none",
        class: "hover-effect"
      }, null, 8, ["list"])
    ]),
    _createVNode(_component_SectionTitle, {
      title: '',
      class: "mt-5"
    }),
    _createVNode(_component_SectionHeader, {
      title: _ctx.t('servicesPage.projects.categories.cat2.header.title'),
      description: _ctx.t('home.projects.cards.description2'),
      image: _ctx.imgSports,
      txtPadding: "17rem",
      scale: "0.5",
      height: "350px"
    }, null, 8, ["title", "description", "image"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_CustomCards, {
        list: _ctx.CustomCardsListCat2,
        imgWidth: "100%",
        displayTitle: "none",
        class: "hover-effect"
      }, null, 8, ["list"])
    ]),
    _createVNode(_component_SectionTitle, {
      title: '',
      class: "mt-5"
    }),
    _createVNode(_component_SectionHeader, {
      title: _ctx.t('servicesPage.projects.categories.cat3.header.title'),
      description: _ctx.t('home.projects.cards.description5'),
      image: _ctx.imgMedical,
      txtPadding: "17rem",
      scale: "0.5",
      height: "350px"
    }, null, 8, ["title", "description", "image"]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_CustomCards, {
        list: _ctx.CustomCardsListCat3,
        imgWidth: "100%",
        displayTitle: "none",
        class: "hover-effect"
      }, null, 8, ["list"])
    ]),
    _createVNode(_component_SectionTitle, {
      title: '',
      class: "mt-5"
    }),
    _createVNode(_component_SectionHeader, {
      title: _ctx.t('servicesPage.projects.categories.cat4.header.title'),
      description: _ctx.t('home.projects.cards.description6'),
      image: _ctx.imgHR,
      txtPadding: "17rem",
      scale: "0.5",
      height: "350px"
    }, null, 8, ["title", "description", "image"]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_CustomCards, {
        list: _ctx.CustomCardsListCat4,
        imgWidth: "100%",
        displayTitle: "none",
        class: "hover-effect"
      }, null, 8, ["list"])
    ]),
    _createVNode(_component_SectionTitle, {
      title: '',
      class: "mt-5"
    }),
    _createVNode(_component_SectionHeader, {
      title: _ctx.t('servicesPage.projects.categories.cat5.header.title'),
      description: _ctx.t('home.projects.cards.description4'),
      image: _ctx.imgPOS,
      txtPadding: "17rem",
      scale: "0.5",
      height: "350px"
    }, null, 8, ["title", "description", "image"]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_CustomCards, {
        list: _ctx.CustomCardsListCat5,
        imgWidth: "100%",
        displayTitle: "none",
        class: "hover-effect"
      }, null, 8, ["list"])
    ]),
    _createVNode(_component_SectionTitle, {
      title: '',
      class: "mt-5"
    }),
    _createVNode(_component_SectionHeader, {
      title: _ctx.t('servicesPage.projects.categories.cat6.header.title'),
      description: 
        _ctx.t('servicesPage.projects.categories.cat6.header.description')
      ,
      image: _ctx.imgProjectsHeader,
      txtPadding: "17rem",
      scale: "0.5",
      height: "350px"
    }, null, 8, ["title", "description", "image"]),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_component_CustomCards, {
        list: _ctx.CustomCardsListCat6,
        imgWidth: "100%",
        displayTitle: "none",
        class: "hover-effect"
      }, null, 8, ["list"])
    ]),
    _createVNode(_component_SectionTitle, {
      title: '',
      class: "mt-5"
    }),
    _createVNode(_component_SectionHeader, {
      title: _ctx.t('home.clients.header.title'),
      description: _ctx.t('home.clients.header.description'),
      image: _ctx.imgClientsHeader,
      txtPadding: "17rem",
      scale: "0.5",
      height: "350px"
    }, null, 8, ["title", "description", "image"]),
    _createElementVNode("div", _hoisted_7, [
      _createVNode(_component_b_button, {
        pill: "",
        variant: "outline-success"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_router_link, { to: { name: 'Clients' } }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('navbar.ClientsAndPartners')), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ])
  ]))
}