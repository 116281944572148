
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';
import Slider from './components/Slider.vue';
import Projects from './components/Projects.vue';
import { scrollToTop } from './../../utils/helper';

export default defineComponent({
  components: {
    Slider,
    Projects,
  },
  created() {
    scrollToTop();
  },
  setup() {
    const { t } = useI18n();

    return { t };
  },
});
