
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';
import CustomCard from './../../../types/CustomCard';
import SectionTitle from './../../../components/SectionTitle.vue';
import SectionHeader from './../../../components/SectionHeader.vue';
import CustomCards from './../../../components/CustomCards.vue';
import {
  imgClientsHeader,
  imgProjectsHeader,
  imgEducational,
  // educational CAT1//
  imgNileLogo,
  imgArcplanLogo,
  imgInternationalAcademy,
  imgBritishInternationalCollegeOfCairo,
  imgFutureAcademy,
  imgIdress,
  imgTohfaEducationalGate,
  imgInCenter,
  imgVCA,
  imgLePetitSavant,
  imgForumForVocationalTraining,
  imgEgyptAcademy,
  imgSports,
  imgMedical,
  imgHR,
  imgPOS,
  imgArabContrectorsFCLogo,
  imgAlphaAcademy,
  imgElixirAwadShalapy,
  imgElmohands,
  imgElsaidInMath,
  imgElwadeh,
  imgGoSmartAmericanAcademy,
  imgMath4u,
  imgMenofiaCoursesAcademy,
  imgModernOnlineSchool,
  imgTeachMe,
  // #region Sports CAT2
  imgBayernAcademy,
  imgDolvinusAcademy,
  imgDoubleDotSquashAcademy,
  imgSwimmer,
  imgGoldenSwimming,
  imgKoraMasr,
  imgLionsAcademy,
  imgPerfrctSportingAcademy,
  imgPowerTeam,
  imgRefit,
  imgSantos,
  imgSherwqAcademy,
  imgSportStar,
  imgSwimClub,
  // #endregion Sports CAT2
  // #region Medical CAT3
  imgAjiad,
  imgAGBiology,
  imgAldalal,
  imgAlElizehClinics,
  imgAlRajhi,
  imgArttop,
  imgDeiQuik,
  imgElgendy,
  imgPanoramaRedaHelmy,
  imgTatbib,
  imgWelcareClinics,
  imgWellnessClinics,
  imgZamzamClinics,
  // #endregion Medical CAT3
  // #region HR CAT4
  imgPremco,
  imgBioStream,
  imgJbsDenim,
  imgSamanaSwtak,
  // #endregion HR CAT4
  // #region POS CAT5
  imgAlsharq,
  imgCollection,
  imgClientLogoApache,
  // #endregion POS CAT5
  // #region Custom CAT6
  imgSaveCard,
  imgPartnerSky,
  // #endregion Custom CAT6
} from './../../../utils/images';

export default defineComponent({
  components: { SectionTitle, SectionHeader, CustomCards },
  setup() {
    const { t } = useI18n();
    const CustomCardsListCat1: CustomCard[] = [
      {
        title: t('servicesPage.projects.categories.cat1.cards.title1'),
        description: '',
        image: imgNileLogo,
      },
      {
        title: t('servicesPage.projects.categories.cat1.cards.title2'),
        description: '',
        image: imgArcplanLogo,
      },
      {
        title: t('servicesPage.projects.categories.cat1.cards.title3'),
        description: '',
        image: imgInternationalAcademy,
      },
      {
        title: t('servicesPage.projects.categories.cat1.cards.title4'),
        description: '',
        image: imgBritishInternationalCollegeOfCairo,
      },
      {
        title: t('servicesPage.projects.categories.cat1.cards.title5'),
        description: '',
        image: imgFutureAcademy,
      },
      {
        title: t('servicesPage.projects.categories.cat1.cards.title6'),
        description: '',
        image: imgIdress,
      },
      {
        title: t('servicesPage.projects.categories.cat1.cards.title7'),
        description: '',
        image: imgTohfaEducationalGate,
      },
      {
        title: t('servicesPage.projects.categories.cat1.cards.title8'),
        description: '',
        image: imgInCenter,
      },
      {
        title: t('servicesPage.projects.categories.cat1.cards.title9'),
        description: '',
        image: imgVCA,
      },
      {
        title: t('servicesPage.projects.categories.cat1.cards.title10'),
        description: '',
        image: imgLePetitSavant,
      },
      {
        title: t('servicesPage.projects.categories.cat1.cards.title11'),
        description: '',
        image: imgForumForVocationalTraining,
      },
      {
        title: t('servicesPage.projects.categories.cat1.cards.title12'),
        description: '',
        image: imgEgyptAcademy,
      },
      {
        title: t('servicesPage.projects.categories.cat1.cards.title13'),
        description: '',
        image: imgPanoramaRedaHelmy,
      },
      {
        title: t('servicesPage.projects.categories.cat1.cards.title14'),
        description: '',
        image: imgAGBiology,
      },
      {
        title: t('servicesPage.projects.categories.cat1.cards.title15'),
        description: '',
        image: imgAlphaAcademy,
      },
      {
        title: t('servicesPage.projects.categories.cat1.cards.title16'),
        description: '',
        image: imgElixirAwadShalapy,
      },
      {
        title: t('servicesPage.projects.categories.cat1.cards.title17'),
        description: '',
        image: imgElmohands,
      },
      {
        title: t('servicesPage.projects.categories.cat1.cards.title18'),
        description: '',
        image: imgElsaidInMath,
      },
      {
        title: t('servicesPage.projects.categories.cat1.cards.title19'),
        description: '',
        image: imgElwadeh,
      },
      {
        title: t('servicesPage.projects.categories.cat1.cards.title20'),
        description: '',
        image: imgGoSmartAmericanAcademy,
      },
      {
        title: t('servicesPage.projects.categories.cat1.cards.title21'),
        description: '',
        image: imgMath4u,
      },
      {
        title: t('servicesPage.projects.categories.cat1.cards.title23'),
        description: '',
        image: imgMenofiaCoursesAcademy,
      },
      {
        title: t('servicesPage.projects.categories.cat1.cards.title24'),
        description: '',
        image: imgModernOnlineSchool,
      },
      {
        title: t('servicesPage.projects.categories.cat1.cards.title25'),
        description: '',
        image: imgTeachMe,
      },
    ];
    const CustomCardsListCat2: CustomCard[] = [
      {
        title: t('servicesPage.projects.categories.cat2.cards.title1'),
        description: '',
        image: imgArabContrectorsFCLogo,
      },
      {
        title: t('servicesPage.projects.categories.cat2.cards.title9'),
        description: '',
        image: imgKoraMasr,
      },
      {
        title: t('servicesPage.projects.categories.cat2.cards.title2'),
        description: '',
        image: imgBayernAcademy,
      },
      {
        title: t('servicesPage.projects.categories.cat2.cards.title19'),
        description: '',
        image: imgSwimClub,
      },

      {
        title: t('servicesPage.projects.categories.cat2.cards.title14'),
        description: '',
        image: imgRefit,
      },
      {
        title: t('servicesPage.projects.categories.cat2.cards.title15'),
        description: '',
        image: imgSantos,
      },
      {
        title: t('servicesPage.projects.categories.cat2.cards.title18'),
        description: '',
        image: imgSportStar,
      },
      {
        title: t('servicesPage.projects.categories.cat2.cards.title17'),
        description: '',
        image: imgSherwqAcademy,
      },
      {
        title: t('servicesPage.projects.categories.cat2.cards.title12'),
        description: '',
        image: imgPerfrctSportingAcademy,
      },
      {
        title: t('servicesPage.projects.categories.cat2.cards.title5'),
        description: '',
        image: imgDolvinusAcademy,
      },
      {
        title: t('servicesPage.projects.categories.cat2.cards.title6'),
        description: '',
        image: imgDoubleDotSquashAcademy,
      },
      {
        title: t('servicesPage.projects.categories.cat2.cards.title7'),
        description: '',
        image: imgSwimmer,
      },
      {
        title: t('servicesPage.projects.categories.cat2.cards.title8'),
        description: '',
        image: imgGoldenSwimming,
      },
      {
        title: t('servicesPage.projects.categories.cat2.cards.title10'),
        description: '',
        image: imgLionsAcademy,
      },

      {
        title: t('servicesPage.projects.categories.cat2.cards.title13'),
        description: '',
        image: imgPowerTeam,
      },
    ];
    const CustomCardsListCat3: CustomCard[] = [
      {
        title: t('servicesPage.projects.categories.cat3.cards.title1'),
        description: '',
        image: imgAjiad,
      },
      {
        title: t('servicesPage.projects.categories.cat3.cards.title2'),
        description: '',
        image: imgAldalal,
      },
      {
        title: t('servicesPage.projects.categories.cat3.cards.title3'),
        description: '',
        image: imgAlElizehClinics,
      },
      {
        title: t('servicesPage.projects.categories.cat3.cards.title4'),
        description: '',
        image: imgAlRajhi,
      },
      {
        title: t('servicesPage.projects.categories.cat3.cards.title5'),
        description: '',
        image: imgArttop,
      },
      {
        title: t('servicesPage.projects.categories.cat3.cards.title6'),
        description: '',
        image: imgDeiQuik,
      },
      {
        title: t('servicesPage.projects.categories.cat3.cards.title7'),
        description: '',
        image: imgElgendy,
      },
      {
        title: t('servicesPage.projects.categories.cat3.cards.title8'),
        description: '',
        image: imgTatbib,
      },
      {
        title: t('servicesPage.projects.categories.cat3.cards.title9'),
        description: '',
        image: imgWelcareClinics,
      },
      {
        title: t('servicesPage.projects.categories.cat3.cards.title10'),
        description: '',
        image: imgWellnessClinics,
      },
      {
        title: t('servicesPage.projects.categories.cat3.cards.title11'),
        description: '',
        image: imgZamzamClinics,
      },
    ];
    const CustomCardsListCat4: CustomCard[] = [
      {
        title: t('servicesPage.projects.categories.cat4.cards.title1'),
        description: '',
        image: imgPremco,
      },
      {
        title: t('servicesPage.projects.categories.cat4.cards.title2'),
        description: '',
        image: imgBioStream,
      },
      {
        title: t('servicesPage.projects.categories.cat4.cards.title3'),
        description: '',
        image: imgJbsDenim,
      },
      {
        title: t('servicesPage.projects.categories.cat4.cards.title4'),
        description: '',
        image: imgSamanaSwtak,
      },
    ];
    const CustomCardsListCat5: CustomCard[] = [
      {
        title: t('servicesPage.projects.categories.cat5.cards.title1'),
        description: '',
        image: imgAlsharq,
      },
      {
        title: t('servicesPage.projects.categories.cat5.cards.title2'),
        description: '',
        image: imgCollection,
      },
      {
        title: t('servicesPage.projects.categories.cat5.cards.title3'),
        description: '',
        image: imgKoraMasr,
      },
      {
        title: t('servicesPage.projects.categories.cat5.cards.title4'),
        description: '',
        image: imgClientLogoApache,
      },
      {
        title: t('servicesPage.projects.categories.cat1.cards.title4'),
        description: '',
        image: imgBritishInternationalCollegeOfCairo,
      },
    ];
    const CustomCardsListCat6: CustomCard[] = [
      {
        title: t('servicesPage.projects.categories.cat6.cards.title1'),
        description: '',
        image: imgSaveCard,
      },
      {
        title: t('home.clients.cards.title1'),
        description: '',
        image: imgPartnerSky,
      },
    ];

    return {
      t,
      imgClientsHeader,
      imgEducational,
      imgSports,
      imgMedical,
      imgHR,
      imgPOS,
      imgProjectsHeader,
      CustomCardsListCat1,
      CustomCardsListCat2,
      CustomCardsListCat3,
      CustomCardsListCat4,
      CustomCardsListCat5,
      CustomCardsListCat6,
    };
  },
});
